import { PageActionType } from '../model/page-action-type.model';
import { action, computed, makeObservable, observable } from 'mobx';

export interface ModalStoreConfig {
	onOpen?: (data?: Record<string, any>) => void;
	onClose?: () => void
}

export class ViewModalStore<T = Record<string, any>> {

	onOpen?: (data?: Record<string, any>) => void;

	onClose?: () => void;

	constructor(public config?: ModalStoreConfig) {
		this.onOpen = this.config?.onOpen;
		this.onClose = this.config?.onClose;

		makeObservable(this, {
			visible: observable,
			data: observable,
			actionType: observable,
			openModal: action.bound,
			closeModal: action.bound,
			setData: action.bound,
			setVisible: action.bound,
			setActionType: action.bound,
			isUpdate: computed,
		});
	}

	visible = false;

	actionType = PageActionType.ADD;

	data?: T = undefined;

	openModal(data?: T, actionType?: PageActionType) {
		const _actionType = actionType || data ? PageActionType.UPDATE : PageActionType.ADD;
		this.setActionType(_actionType);
		this.setData(data);
		this.setVisible(true);
		if (this.onOpen) {
			this.onOpen(data);
		}
	}

	setVisible(visible: boolean) {
		this.visible = visible;
	}

	setActionType(actionType: PageActionType) {
		this.actionType = actionType;
	}

	setData(data?: T) {
		this.data = data;
	}

	closeModal() {
		this.setVisible(false);
		this.setData(undefined);
		if (this.onClose) {
			this.onClose();
		}
	}

	get isUpdate(): boolean {
		return this.actionType === PageActionType.UPDATE;
	};

}
